import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f3f4f6",
      }}
    >
      <Typography variant="h4" gutterBottom>
        404: The page you are looking for isn’t here
      </Typography>
      <Typography variant="subtitle1">
        You might have the wrong address, or the page may have moved.
      </Typography>
      <Button onClick={() => navigate("/")} sx={{ mt: 2 }}>
        Back to Home
      </Button>
    </Box>
  );
};

export default NotFoundPage;
