

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Typography, Box, Stack } from "@mui/material";
import Nav from "../../utils/Navbar/Nav";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import ThankYou from "../../assets/ThankYou.png"
import Footer from "../../utils/utils/Footer";
import Button from "../../utils/Button";
import NavLight from "../../utils/Navbar/NavLight";
const ThankYouPage = () => {
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    // Get the valid transaction ID from session storage
    const validTxnId = localStorage.getItem("validTxnId");

    if (!transactionId || transactionId !== validTxnId) {
      navigate("/404-Not-Found"); // Redirect to home if the transaction ID is not valid
      return;
    }

    const timer =
      countdown > 0
        ? setInterval(() => {
            setCountdown(countdown - 1);
          }, 1000)
        : navigate("/"); // Redirect to home after countdown

    return () => {
      clearInterval(timer);
      sessionStorage.removeItem("validTxnId"); // Clear the transaction ID from session storage after use
    };
  }, [countdown, navigate, transactionId]);

  return (
    <>
      <NavLight />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        padding={{ xl: "80px", sm: "50px 0 30px 0" }}
      >
        <Stack width={{ xl: "42%",lg:"40%", sm: "82%" }} pt={{ sm: 3 }}>
          <img
            src={ThankYou}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </Stack>
        <Typography
          fontSize={{ xl: "40px", sm: "20px" }}
          component="h1"
          gutterBottom
        >
          Thanks for reaching out !!
        </Typography>
        <Typography
          fontSize={{ xl: "20px", sm: "10px" }}
          textAlign={"center"}
          p={{ sm: "15px", xl: "0" }}
        >
          A confirmation email has been sent to your address. Please check your
          inbox to follow the next steps. <br />
          You will be redirected in {countdown} seconds.
        </Typography>
        <Stack pt={{xl:"25px",sm:"0px"}}>
          <Link
            to={"/"}
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <Button
              sx={{
                height: "5vh",
                p: "13px",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#F15B25",
                  color: "white",
                },
              }}
            >
              Back to Home
            </Button>
          </Link>
        </Stack>
      </Box>
      <Footer />
    </>
  );
};

export default ThankYouPage;
