import { Stack } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";


const Dummy = () => {
  return (
    <Stack p={10}>
      <QRCode value="F2/33346/629039/0/1717419259/1717419086"/>
    </Stack>
  );
  
};

export default Dummy;
