
// import { Box, Stack, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import IconReview from "../../assets/IconReview.png";
// import Img from "../../assets/Img.png";
// import axios from "axios";
// import Button from "../../utils/Button";
// import Carousel from "react-multi-carousel";
// import "./Features.css";
// import { Link } from "react-router-dom";

// const CustomerReview = () => {
//   const responsive = {
//     superLargeDesktop: {
//       breakpoint: { max: 4000, min: 3000 },
//       items: 3,
//     },
//     newdesktop: {
//       breakpoint: { max: 3000, min: 1221 },
//       items: 3,
//     },
//     desktop: {
//       breakpoint: { max: 1220, min: 530 },
//       items: 2,
//     },
//     tablet: {
//       breakpoint: { max: 530, min: 464 },
//       items: 1,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//     },
//   };

//   const [reviews, setReviews] = useState([]);

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await axios.get(
//           "https://api.saniiro.com/api/v1/customer/review"
//         );
//         setReviews(
//           response.data.Data.map((review) => ({ ...review, expanded: false }))
//         );
//       } catch (error) {
//         console.error("Failed to fetch reviews:", error);
//       }
//     };
//     fetchReviews();
//   }, []);

//   const toggleReview = (index) => {
//     setReviews((prevReviews) => {
//       const newReviews = [...prevReviews];
//       newReviews[index].expanded = !newReviews[index].expanded;
//       return newReviews;
//     });
//   };

//   return (
//     <>
//       <Stack
//         bgcolor={"#052973"}
//         p={"51px 20px"}
//         gap={{ xl: "70px", sm: "40px" }}
//       >
//         <Typography
//           fontSize={{ xl: "50px", md: "40px", sm: "22px" }}
//           fontWeight={"bold"}
//           color={"white"}
//           textAlign={"center"}
//         >
//           What customers say about Saniiro
//         </Typography>
//         <Stack
//           direction={"row"}
//           flexWrap={"wrap"}
//           gap={"35px"}
//           rowGap={"75px"}
//           justifyContent={"center"}
//         >
//           <Box width="100%">
//             <Carousel
//               responsive={responsive}
//               showDots
//               infinite
//               autoPlaySpeed={1500}
//               removeArrowOnDeviceType={[
//                 "newdesktop",
//                 "desktop",
//                 "tablet",
//                 "mobile",
//               ]}
//             >
//               {reviews.map((d, index) => (
//                 <Stack
//                   m={{ lg: "25px", md: "20px", smm: "10px" }}
//                   bgcolor={"white"}
//                   borderRadius={2}
//                   p={"15px 24px 15px 24px"}
//                   gap={"11px"}
//                   key={index}
//                   position={"relative"}
//                 >
//                   <Stack
//                     alignItems={"center"}
//                     bottom={{ xl: "79%", sm: "76%" }}
//                     left={{ xl: "35%", sm: "36%" }}
//                   >
//                     <img
//                       src={d.ImageName !== null ? d.ImageName : Img}
//                       alt=""
//                       width={"95px"}
//                       height={"85px"}
//                     />
//                   </Stack>
//                   <Stack direction={"row"} gap={2}>
//                     <Stack display={{ xl: "flex", sm: "none" }}>
//                       <img
//                         src={IconReview}
//                         alt=""
//                         width={"38px"}
//                         height={"39px"}
//                       />
//                     </Stack>
//                     <Typography
//                       fontSize={"10px"}
//                       width={{
//                         xs: "100%",
//                         md: "95%",
//                       }}
//                       overflow={"hidden"}
//                       textAlign={"justify"}
//                     >
//                       {d.expanded ? (
//                         <>
//                           {d.Review}{" "}
//                           <span
//                             style={{
//                               color: "#052973",
//                               cursor: "pointer",
//                               fontWeight: "bold",
//                             }}
//                             onClick={() => toggleReview(index)}
//                           >
//                             View Less
//                           </span>
//                         </>
//                       ) : (
//                         <>
//                           {d.Review.substring(0, 130)}...{" "}
//                           <span
//                             style={{
//                               color: "#052973",
//                               cursor: "pointer",
//                               fontWeight: "bold",
//                             }}
//                             onClick={() => toggleReview(index)}
//                           >
//                             View More
//                           </span>
//                         </>
//                       )}
//                     </Typography>
//                   </Stack>
//                   <Stack direction={"row"} justifyContent={"end"} gap={1}>
//                     <Box>
//                       <Typography fontWeight={"bold"}>{d.Name}</Typography>
//                       <Typography fontSize={"11px"}>{d.Designation}</Typography>
//                     </Box>
//                   </Stack>
//                 </Stack>
//               ))}
//             </Carousel>
//           </Box>
//         </Stack>
//         <Stack alignItems={"center"}>
//           <Link
//             to={
//               "https://www.google.com/search?gs_ssp=eJwFwUEOQDAQAMC4SvyhB86q1a56gl-0dquboEIjnm-mbvqtH4YUYVVJG1nNnfy0sxgMAgQZ_Ag4yy9GMsorGyeNYMkt7eNP5juLQms68543pkdcN7--kNj54EL4A-VRHaE&q=saniiro+technologies+private+limited&oq=saniiro+technoogies+&gs_lcrp=EgZjaHJvbWUqDwgBEC4YDRivARjHARiABDIGCAAQRRg5Mg8IARAuGA0YrwEYxwEYgAQyCggCEAAYgAQYogQyCggDEAAYgAQYogQyCggEEAAYgAQYogTSAQg2NjQzajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x396db5d77b0ba47d:0xffe52a26f83d76e9,1"
//             }
//             target="_blank"
//           >
//             <Button
//               type="button"
//               sx={{
//                 width: {
//                   xs: "220px",
//                   sm: "268px",
//                   smm: "300px",
//                   md: "340px",
//                   lg: "385px",
//                   xl: "350px",
//                 },
//                 height: {
//                   xs: "45px",
//                   sm: "45px",
//                   md: "50px",
//                   lg: "57px",
//                   xl: "67px",
//                 },
//                 color: "white",
//                 fontSize: {
//                   xs: "16px",
//                   sm: "19px",
//                   md: "22px",
//                 },
//                 bgcolor: "#F15B25",
//                 fontWeight: "bold",
//                 "&:hover": {
//                   backgroundColor: "white",
//                   border: "1px solid #052973",
//                   color: "#052973",
//                 },
//               }}
//             >
//               See all reviews
//             </Button>
//           </Link>
//         </Stack>
//       </Stack>
//     </>
//   );
// };

// export default CustomerReview;




// import { Box, Stack, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import IconReview from "../../assets/IconReview.png";
// import Img from "../../assets/Img.png";
// import axios from "axios";
// import Button from "../../utils/Button";
// import Carousel from "react-multi-carousel";
// import "./Features.css";
// import { Link } from "react-router-dom";

// const CustomerReview = () => {
//   const responsive = {
//     superLargeDesktop: {
//       breakpoint: { max: 4000, min: 3000 },
//       items: 3,
//     },
//     newdesktop: {
//       breakpoint: { max: 3000, min: 1221 },
//       items: 3,
//     },
//     desktop: {
//       breakpoint: { max: 1220, min: 530 },
//       items: 2,
//     },
//     tablet: {
//       breakpoint: { max: 530, min: 464 },
//       items: 1,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//     },
//   };

//   const [reviews, setReviews] = useState([]);

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await axios.get(
//           "https://api.saniiro.com/api/v1/customer/review"
//         );
//         setReviews(
//           response.data.Data.map((review) => ({ ...review, expanded: false }))
//         );
//       } catch (error) {
//         console.error("Failed to fetch reviews:", error);
//       }
//     };
//     fetchReviews();
//   }, []);

//   const toggleReview = (index) => {
//     setReviews((prevReviews) => {
//       const newReviews = [...prevReviews];
//       newReviews[index].expanded = !newReviews[index].expanded;
//       return newReviews;
//     });
//   };

//   return (
//     <>
//       <Stack
//         bgcolor={"#052973"}
//         p={"51px 20px"}
//         gap={{ xl: "70px", sm: "40px" }}
//       >
//         <Typography
//           fontSize={{ xl: "50px", md: "40px", sm: "22px" }}
//           fontWeight={"bold"}
//           color={"white"}
//           textAlign={"center"}
//         >
//           What customers say about Saniiro
//         </Typography>
//         <Stack
//           direction={"row"}
//           flexWrap={"wrap"}
//           gap={"35px"}
//           rowGap={"75px"}
//           justifyContent={"center"}
//         >
//           <Box width="100%">
//             <Carousel
//               responsive={responsive}
//               showDots
//               infinite
//               autoPlaySpeed={1500}
//               removeArrowOnDeviceType={[
//                 "newdesktop",
//                 "desktop",
//                 "tablet",
//                 "mobile",
//               ]}
//             >
//               {reviews.map((d, index) => (
//                 <Stack
//                   m={{ lg: "25px", md: "20px", smm: "10px" }}
//                   bgcolor={"white"}
//                   borderRadius={2}
//                   p={"15px 24px 15px 24px"}
//                   gap={"11px"}
//                   key={index}
//                   position={"relative"}
//                 >
//                   <Stack
//                     alignItems={"center"}
//                     bottom={{ xl: "79%", sm: "76%" }}
//                     left={{ xl: "35%", sm: "36%" }}
//                   >
//                     <img
//                       src={d.ImageName !== null ? d.ImageName : Img}
//                       alt=""
//                       width={"95px"}
//                       height={"85px"}
//                     />
//                   </Stack>
//                   <Stack direction={"row"} gap={2}>
//                     <Stack display={{ xl: "flex", sm: "none" }}>
//                       <img
//                         src={IconReview}
//                         alt=""
//                         width={"38px"}
//                         height={"39px"}
//                       />
//                     </Stack>
//                     <Typography
//                       fontSize={"10px"}
//                       width={{
//                         xs: "100%",
//                         md: "95%",
//                       }}
//                       overflow={"hidden"}
//                       textAlign={"justify"}
//                     >
//                       {d.Review.length > 130 ? (
//                         d.expanded ? (
//                           <>
//                             {d.Review}{" "}
//                             <span
//                               style={{
//                                 color: "#052973",
//                                 cursor: "pointer",
//                                 fontWeight: "bold",
//                               }}
//                               onClick={() => toggleReview(index)}
//                             >
//                               View Less
//                             </span>
//                           </>
//                         ) : (
//                           <>
//                             {d.Review.substring(0, 130)}...{" "}
//                             <span
//                               style={{
//                                 color: "#052973",
//                                 cursor: "pointer",
//                                 fontWeight: "bold",
//                               }}
//                               onClick={() => toggleReview(index)}
//                             >
//                               View More
//                             </span>
//                           </>
//                         )
//                       ) : (
//                         d.Review
//                       )}
//                     </Typography>
//                   </Stack>
//                   <Stack direction={"row"} justifyContent={"end"} gap={1}>
//                     <Box>
//                       <Typography fontWeight={"bold"}>{d.Name}</Typography>
//                       <Typography fontSize={"11px"}>{d.Designation}</Typography>
//                     </Box>
//                   </Stack>
//                 </Stack>
//               ))}
//             </Carousel>
//           </Box>
//         </Stack>
//         <Stack alignItems={"center"}>
//           <Link
//             to={
//               "https://www.google.com/search?gs_ssp=eJwFwUEOQDAQAMC4SvyhB86q1a56gl-0dquboEIjnm-mbvqtH4YUYVVJG1nNnfy0sxgMAgQZ_Ag4yy9GMsorGyeNYMkt7eNP5juLQms68543pkdcN7--kNj54EL4A-VRHaE&q=saniiro+technologies+private+limited&oq=saniiro+technoogies+&gs_lcrp=EgZjaHJvbWUqDwgBEC4YDRivARjHARiABDIGCAAQRRg5Mg8IARAuGA0YrwEYxwEYgAQyCggCEAAYgAQYogQyCggDEAAYgAQYogQyCggEEAAYgAQYogTSAQg2NjQzajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x396db5d77b0ba47d:0xffe52a26f83d76e9,1"
//             }
//             target="_blank"
//           >
//             <Button
//               type="button"
//               sx={{
//                 width: {
//                   xs: "220px",
//                   sm: "268px",
//                   smm: "300px",
//                   md: "340px",
//                   lg: "385px",
//                   xl: "350px",
//                 },
//                 height: {
//                   xs: "45px",
//                   sm: "45px",
//                   md: "50px",
//                   lg: "57px",
//                   xl: "67px",
//                 },
//                 color: "white",
//                 fontSize: {
//                   xs: "16px",
//                   sm: "19px",
//                   md: "22px",
//                 },
//                 bgcolor: "#F15B25",
//                 fontWeight: "bold",
//                 "&:hover": {
//                   backgroundColor: "white",
//                   border: "1px solid #052973",
//                   color: "#052973",
//                 },
//               }}
//             >
//               See all reviews
//             </Button>
//           </Link>
//         </Stack>
//       </Stack>
//     </>
//   );
// };

// export default CustomerReview;





import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconReview from "../../assets/IconReview.png";
import Img from "../../assets/Img.png";
import axios from "axios";
import Button from "../../utils/Button";
import Carousel from "react-multi-carousel";
import "./Features.css";
import { Link } from "react-router-dom";

const CustomerReview = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    newdesktop: {
      breakpoint: { max: 3000, min: 1221 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1220, min: 530 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 530, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          "https://api.saniiro.com/api/v1/customer/review"
        );
        setReviews(
          response.data.Data.map((review) => ({ ...review, expanded: false }))
        );
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
      }
    };
    fetchReviews();
  }, []);

  const toggleReview = (index) => {
    setReviews((prevReviews) => {
      const newReviews = [...prevReviews];
      newReviews[index].expanded = !newReviews[index].expanded;
      return newReviews;
    });
  };

  return (
    <>
      <Stack
        bgcolor={"#052973"}
        p={"51px 20px"}
        gap={{ xl: "70px", sm: "40px" }}
      >
        <Typography
          fontSize={{ xl: "50px", md: "40px", sm: "22px" }}
          fontWeight={"bold"}
          color={"white"}
          textAlign={"center"}
        >
          What customers say about Saniiro
        </Typography>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          gap={"35px"}
          rowGap={"75px"}
          justifyContent={"center"}
        >
          <Box width="100%">
            <Carousel
              responsive={responsive}
              showDots
              infinite
              autoPlaySpeed={1500}
              removeArrowOnDeviceType={[
                "newdesktop",
                "desktop",
                "tablet",
                "mobile",
              ]}
            >
              {reviews.map((d, index) => (
                <Stack
                  m={{ lg: "25px", md: "20px", smm: "10px" }}
                  bgcolor={"white"}
                  borderRadius={2}
                  p={"15px 24px 15px 24px"}
                  gap={"11px"}
                  key={index}
                  position={"relative"}
                >
                  <Stack
                    alignItems={"center"}
                    bottom={{ xl: "79%", sm: "76%" }}
                    left={{ xl: "35%", sm: "36%" }}
                  >
                    <img
                      src={d.ImageName !== null ? d.ImageName : Img}
                      alt=""
                      width={"95px"}
                      height={"85px"}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={2}>
                    <Stack display={{ xl: "flex", sm: "none" }}>
                      <img
                        src={IconReview}
                        alt=""
                        width={"38px"}
                        height={"39px"}
                      />
                    </Stack>
                    <Typography
                      fontSize={"10px"}
                      width={{
                        xs: "100%",
                        md: "95%",
                      }}
                      overflow={"hidden"}
                      textAlign={"justify"}
                      height={d.Review.length <= 130 ? "42px" : "fit-content"}
                    >
                      {d.expanded ? (
                        <>
                          {d.Review}{" "}
                          {d.Review.length > 130 && (
                            <span
                              style={{
                                color: "#052973",
                                cursor: "pointer",
                                fontWeight: "bold",
                              }}
                              onClick={() => toggleReview(index)}
                            >
                              View Less
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {d.Review.substring(0, 130)}...{" "}
                          {d.Review.length > 130 && (
                            <span
                              style={{
                                color: "#052973",
                                cursor: "pointer",
                                fontWeight: "bold",
                              }}
                              onClick={() => toggleReview(index)}
                            >
                              View More
                            </span>
                          )}
                        </>
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"end"} gap={1}>
                    <Box>
                      <Typography fontWeight={"bold"}>{d.Name}</Typography>
                      <Typography fontSize={"11px"}>{d.Designation}</Typography>
                    </Box>
                  </Stack>
                </Stack>
              ))}
            </Carousel>
          </Box>
        </Stack>
        <Stack alignItems={"center"}>
          <Link
            to={
              "https://www.google.com/search?gs_ssp=eJwFwUEOQDAQAMC4SvyhB86q1a56gl-0dquboEIjnm-mbvqtH4YUYVVJG1nNnfy0sxgMAgQZ_Ag4yy9GMsorGyeNYMkt7eNP5juLQms68543pkdcN7--kNj54EL4A-VRHaE&q=saniiro+technologies+private+limited&oq=saniiro+technoogies+&gs_lcrp=EgZjaHJvbWUqDwgBEC4YDRivARjHARiABDIGCAAQRRg5Mg8IARAuGA0YrwEYxwEYgAQyCggCEAAYgAQYogQyCggDEAAYgAQYogQyCggEEAAYgAQYogTSAQg2NjQzajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x396db5d77b0ba47d:0xffe52a26f83d76e9,1"
            }
            target="_blank"
          >
            <Button
              type="button"
              sx={{
                width: {
                  xs: "220px",
                  sm: "268px",
                  smm: "300px",
                  md: "340px",
                  lg: "385px",
                  xl: "350px",
                },
                height: {
                  xs: "45px",
                  sm: "45px",
                  md: "50px",
                  lg: "57px",
                  xl: "67px",
                },
                color: "white",
                fontSize: {
                  xs: "16px",
                  sm: "19px",
                  md: "22px",
                },
                bgcolor: "#F15B25",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "white",
                  border: "1px solid #052973",
                  color: "#052973",
                },
              }}
            >
              See all reviews
            </Button>
          </Link>
        </Stack>
      </Stack>
    </>
  );
};

export default CustomerReview;
