import React from 'react'
import PayLink from './PayLink'

const PayuMoney = () => {
  return (
    <>
      <div>Make Payment</div>
      <PayLink />
    </>
  );
}

export default PayuMoney