import React from 'react'
import Nav from '../utils/Navbar/Nav'
// import Simplify from './parts/Simplify';
import Billing from './parts/Billing';
import Clock from './parts/Clock';
import ContactFaq from './parts/ContactFaq';
import Testimonial from './parts/Testimonial';
import CustomerReview from './parts/CustomerReview';
import Features from './parts/Features';
import FeaturesMain from './parts/FeaturesMain';
import UserFav from './parts/UserFav';
import Awards from './Awards';
import { Box, Stack } from '@mui/material';
import CustomerSlider from './parts/CustomerSlider';
import Dummy from '../utils/Dummy';
import Simplify from './parts/Simplify';
import FeaturesMainNew from './parts/FeaturesMainNew';
import IconSection from './parts/IconSection';
import ProductSlider from './parts/Reviews';
import Name from './parts/Name';
import Footer from '../utils/utils/Footer';
import CtsRow from './parts/CtsRow';
import SliderSection from './parts/MarqueSection';
import Twosection from './parts/Twosection';

const Home = () => {
  return (
    <Box maxWidth={"1600px"} margin={"0 auto"} fontFamily={"Work Sans"}>
      <Nav />
      <Simplify />
      <FeaturesMain />
      <CtsRow/>
      <IconSection/>
      <SliderSection/>
      <Twosection/>
      <CustomerReview />
      <Name/>
      <Clock />
      <ContactFaq />
      <Footer/>
    </Box>
  );
}

export default Home