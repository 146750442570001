// src/components/SliderSection.js
// import React, { useState } from "react";
// import Marquee from "react-marquee-slider";
// import { Box, Stack, Typography } from "@mui/material";
// import { styled } from "@mui/system";
// import { v4 as uuidv4 } from "uuid";
// import badge from "../../assets/badge1.svg";
// import line from "../../assets/Line.png"
// const Wrapper = styled(Box)({
//   backgroundColor: "#0073e6", // Your background color
//   padding: "70px 0",
//   textAlign: "center",
//   color: "white",
//   width:"80%",
//   borderRadius:"15px",
//   margin:"50px 0",
//   display:"flex",
//   flexDirection:"column",
//   gap:"20px"
// });

// const Logo = styled("img")({
//   height: 80, // Adjust as needed
//   margin: "0 30px",
// });
import React, { useState } from "react";
import Marquee from "react-marquee-slider";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import badge from "../../assets/badge1.svg";
import payu from "../../assets/payu.svg";
import RazorPay from "../../assets/Razorpay.svg"
import ecom from "../../assets/EcomExpress.svg"
import nsdl from "../../assets/nsdlpng.png";
import msgclub from "../../assets/msglogo.png";
import software from "../../assets/ss_logo.svg";
import Phonepe from "../../assets/phonePe.svg";
import indiamart from "../../assets/indiamart.png";
import digital from "../../assets/digitalocean.png";
import ecommerce from "../../assets/Ecommerce.png";
import crm from "../../assets/Crm.png";



import line from "../../assets/Line.png"
const Wrapper = styled(Box)({
  backgroundColor: "#0073e6", // Your background color
  padding: "50px 0",
  textAlign: "center",
  color: "white",
  position: "relative",
    width:"80%",
    borderRadius:"15px",
    margin:"50px 0",
    display:"flex",
    flexDirection:"column",
    gap:"40px"
});

const BlurOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  bottom: 0,
  width: "10%",
  background: "linear-gradient(to right, #0073e6, transparent)",
  zIndex: 1,
});

const RightBlurOverlay = styled(BlurOverlay)({
  right: 0,
  background: "linear-gradient(to left, #0073e6, transparent)",
  borderRadius:"15px",
  width:"36%"
});

const LeftBlurOverlay = styled(BlurOverlay)({
  left: 0,
  borderRadius: "15px",
  width: "36%",
});

const Logo = styled("img")({
  height: 40, // Adjust as needed
  margin: "0 20px",
  cursor: "pointer",
});


const logos = [
  { src: payu, alt: "Payu" },
  { src: RazorPay, alt: "Razorpay" },
  { src: ecom, alt: "ECOM EXPRESS" },
  { src: nsdl, alt: "NSDL" },
  { src: msgclub, alt: "Msg Club" },
  { src: software, alt: "Software Suggest" },
  { src: Phonepe, alt: "Phone pe" },
  { src: indiamart, alt: "Indiamart" },
  { src: digital, alt: "Digital Ocean" },
  { src: ecommerce, alt: "Eco" },
  { src: crm, alt: "Digital Ocean" },
];

const SliderSection = () => {
    const [marqueeVelocity, setMarqueeVelocity] = useState(10);

    const handleMouseEnter = () => {
      setMarqueeVelocity(0);
    };

    const handleMouseLeave = () => {
      setMarqueeVelocity(10);
    };
  return (
    <Stack alignItems={"center"} bgcolor={"#F6F8FF"}>
      <Wrapper
        sx={{
          backgroundImage: `url(${line})`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LeftBlurOverlay />
        <RightBlurOverlay />
        <Box>
          <Typography
            fontSize={{ lg: "40px", sm: "31px" }}
            color={"#92c4ff"}
            fontWeight={"bold"}
          >
            Integration
          </Typography>
          <Typography
            fontSize={{ lg: "23px", sm: "10px" }}
            fontWeight={"bold"}
            gutterBottom
          >
            Connect and Prosper
          </Typography>
          <Typography fontSize={{ lg: "20px", sm: "8px" }} gutterBottom>
            Maximize your potential through robust app integration.
          </Typography>
        </Box>
        <Box sx={{ position: "relative", zIndex: 0, width: "80%" }}>
          <Marquee
            velocity={marqueeVelocity}
            minScale={0.7}
            resetAfterTries={200}
          >
            {logos.map((logo) => (
              <>
                <Logo
                  sx={{
                    height: { xl: 40, sm: 30 },
                  }}
                  key={uuidv4()}
                  src={logo.src}
                  alt={logo.alt}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
                {/* <Typography fontSize={"12px"}>{logo.alt}</Typography> */}
              </>
            ))}
          </Marquee>
        </Box>
        {/* <Marquee
          velocity={marqueeVelocity}
          minScale={0.7}
          resetAfterTries={200}
        >
          {logos.map((logo) => (
            <>
              <Logo
                key={uuidv4()}
                src={logo.src}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              <Typography>{logo.alt}</Typography>
            </>
          ))}
        </Marquee> */}
      </Wrapper>
    </Stack>
  );
};

export default SliderSection;
