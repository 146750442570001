import { Stack, Typography } from '@mui/material'
import React from 'react'
import Button from '../../utils/Button';

const CtsRow = () => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // This enables the smooth scrolling effect
      });
    };
  return (
    <Stack
      direction={{ xl: "row", md: "column" }}
      alignItems={"Center"}
      justifyContent={"space-around"}
      bgcolor={"#052973"}
      p={5}
      gap={{ md: 5, sm: 5 }}
    >
      <Typography fontWeight={"bold"} fontSize={{xll:"28px",xl:"23px",lg:"26px",md:"20px",smm:"17px",sm:"19px"}} color={"white"} textAlign={"justify"}>
        Join 10k+ SMEs and professionals who trust Saniiro. Get started now
      </Typography>
      <Stack alignItems={"center"}>
        <Button
          onClick={scrollToTop}
          type="button"
          sx={{
            width: {
              xs: "220px",
              sm: "268px",
              smm: "290px",
              md: "300px",
              lg: "325px",
              xl: "300px",
            },
            height: {
              xs: "45px",
              sm: "55px",
              md: "67px",
              lg: "57px",
              xl: "67px",
            },
            color: "white",
            fontSize: {
              xs: "16px",
              sm: "19px",
              md: "22px",
            },
            // border: "1px solid #F15B25",
            bgcolor: "#F15B25",
            // bgcolor: "#F15B25",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "white",
              // border: "1px solid #F15B25",
              border: "1px solid #052973",
              // color: "white",
              color: "#052973",
            },
          }}
        >
          Get Started
        </Button>
      </Stack>
    </Stack>
  );
}

export default CtsRow