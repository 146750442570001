import { Stack, Typography } from '@mui/material';
import React from 'react'
import icon1 from "../../assets/icons/1.png"
import icon2 from "../../assets/icons/2.png";
import icon3 from "../../assets/icons/3.png";
import icon4 from "../../assets/icons/4.png";
import icon5 from "../../assets/icons/5.png";
import icon6 from "../../assets/icons/6.png";
import icon7 from "../../assets/icons/7.png";
import icon8 from "../../assets/icons/8.png";
import icon9 from "../../assets/icons/9.png";
import icon10 from "../../assets/icons/10.png";
import icon11 from "../../assets/icons/11.png";
import icon12 from "../../assets/icons/12.png";
import icon13 from "../../assets/icons/13.png";
import icon14 from "../../assets/icons/14.png";
import icon15 from "../../assets/icons/15.png";
import icon16 from "../../assets/icons/16.png";
import icon17 from "../../assets/icons/17.png";
import icon18 from "../../assets/icons/18.png";
import icon19 from "../../assets/icons/19.png";
import icon20 from "../../assets/icons/20.png";
import icon21 from "../../assets/icons/21.png";
import icon22 from "../../assets/icons/22.png";
import icon23 from "../../assets/icons/23.png";
import icon24 from "../../assets/icons/24.png";
import icon25 from "../../assets/icons/25.png";
import icon26 from "../../assets/icons/26.png";


const IconSection = () => {
    const iconList = [
      {
        src: icon1,
        Title: "GSTR-1 JSON Report",
        Desc: "Simplify tax filing with automatic GSTR-1 JSON report generation. Quick, easy, and error-free.",
      },
      {
        src: icon2,
        Title: "E- Invoicing",
        Desc: "Elevate E-invoicing with NSDL integration and IRN &amp; QR code generation for assured compliance.",
      },
      // {
      //   src: icon3,
      //   Title: "1-click WhatsApp & Email Sharing",
      //   Desc: "Distribute invoices with ease via WhatsApp/email or set them up to be sent later.",
      // },
      {
        src: icon4,
        Title: "Track Invoices",
        Desc: "Accelerate payments with efficient tracking and sophisticated online invoicing tools.",
      },
      // {
      //   src: icon5,
      //   Title: "Auto-assistance",
      //   Desc: "Let our system autofill details, learning from your history, to minimize effort and maximize efficiency.",
      // },
      {
        src: icon6,
        Title: "Branding & Customization",
        Desc: "Craft invoices with a personal touch, choosing from a variety of templates that resonate with your brand identity.",
      },
      {
        src: icon7,
        Title: "1-Click Conversion",
        Desc: "Effortlessly turn quotes into invoices or invoices into credit/debit notes with a single click.",
      },
      // {
      //   src: icon8,
      //   Title: "Automated Payment Reminders",
      //   Desc: "Get paid faster by sending automated reminders to clients over WhatsApp & email.",
      // },
      {
        src: icon9,
        Title: "Multiple Businesses & Team Members",
        Desc: "Efficiently handle multiple businesses and team collaborations with customizable access controls.",
      },
      {
        src: icon10,
        Title: "Invoice",
        Desc: "Generate custom GST/non-GST invoices quickly, with smart automation to streamline your workflow.",
      },
      {
        src: icon11,
        Title: "Quotation & Proforma Invoices",
        Desc: "Produce and share detailed quotes and Proforma Invoices, with option to convert in sale invoices",
      },
      {
        src: icon12,
        Title: "Payment Receipt",
        Desc: "Issue payment/receipts for advances or completed transactions with ease and reliability.",
      },
      {
        src: icon13,
        Title: "Credit/Debit Notes",
        Desc: "Rectify invoicing discrepancies by issuing credit or debit notes promptly.",
      },
      {
        src: icon14,
        Title: "Sales/Purchase Orders",
        Desc: "Centralize the creation and tracking of all sales and purchase orders in one unified space.",
      },
      {
        src: icon15,
        Title: "Proforma Invoice",
        Desc: "Set clear expectations by providing cost estimates with proforma invoices before transactions begin.",
      },
      {
        src: icon16,
        Title: "Delivery Challan",
        Desc: "Generate delivery challans instantly for goods on the move, ensuring every shipment is accounted for.",
      },
      {
        src: icon17,
        Title: "Expense Management",
        Desc: "Record & track all your expenses in one place - keep an eye on profitability.",
      },
      {
        src: icon18,
        Title: "Inventory Management",
        Desc: "Automate inventory tracking with every invoice and expense to maintain accurate stock levels and pricing.",
      },
      {
        src: icon19,
        Title: "Comprehensive Reports",
        Desc: "Click to compile detailed reports on Invoices, Payments, Clients, TDS, GST—business intelligence made easy.",
      },
      {
        src: icon1,
        Title: "Balance Sheet and P&L Report",
        Desc: "Obtain a transparent snapshot of your financial standings for strategic decision-making.",
      },
      {
        src: icon20,
        Title: "Ledgers & Account Groups",
        Desc: "Organize and track your financials with ease, ensuring every account is balanced and updated.",
      },
      {
        src: icon21,
        Title: "Multi-currency Invoices",
        Desc: "Navigate global business with multi-currency invoicing capabilities, tracking forex gains and losses effortlessly.",
      },
      {
        src: icon22,
        Title: "Client & Vendor Management",
        Desc: "Simplify client and vendor relationships with a unified management system—information at your fingertips.",
      },
      {
        src: icon23,
        Title: "Bulk Upload",
        Desc: "Efficiently import historical invoices and client data in bulk, cutting down on time and manual input.",
      },
      {
        src: icon24,
        Title: "Simple Payment Gateway",
        Desc: "Facilitate transactions with a versatile payment gateway, offering a plethora of options and multi-currency processing.",
      },
      {
        src: icon25,
        Title: "Access Anytime Anywhere",
        Desc: "Experience the freedom of access on any device, anywhere you go. Depend on us for unparalleled uptime and reliability.",
      },
      {
        src: icon26,
        Title: "24x7 Live Support",
        Desc: "We’re here around the clock with live support. Reach out via chat, email, WhatsApp, or phone—whenever you need us.",
      },
    ];
  return (
    <Stack p={2} bgcolor={"#F6F8FF"}>
      <Typography
        textAlign={{ xl: "left", sm: "center" }}
        fontWeight={"bold"}
        fontSize={{ xl: "40px", sm: "25px" }}
        p={{ xl: "30px 0 0 136px", lg: "0 37px 0 85px", md: "0 17px 0 48px" }}
      >
        Everything you need in a Business Accounting Software
      </Typography>
      <Stack
        p={"70px 0"}
        direction={"row"}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"center"}
        rowGap={4}
      >
        {iconList.map((d, index) => (
          <Stack
            width={{ lg: "30%", md: "50%", sm: "100%" }}
            height={"200px"}
            alignItems={"center"}
          >
            <Stack
              width={"70%"}
              gap={2}
              alignItems={{ sm: "center", md: "flex-start" }}
            >
              <Stack width={"20%"} height={"38%"}>
                <img src={d.src} alt="" width={"100%"} height={"100%"} />
              </Stack>
              <Typography
                fontWeight={"bold"}
                fontSize={"15px"}
                textAlign={{ sm: "center", md: "justify" }}
              >
                {d.Title}
              </Typography>
              <Typography
                fontSize={"14px"}
                textAlign={{ sm: "center", md: "justify" }}
              >
                {d.Desc}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default IconSection