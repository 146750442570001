// src/components/AppSection.js
import React from "react";
import { Box, Typography, Button, Grid, Stack } from "@mui/material";
import { styled } from "@mui/system";
import mobile from "../../assets/Mobile.png"
import computer from "../../assets/Computer.png"
import play from "../../assets/play.svg";
import software from "../../assets/appstore.svg";
import microsoft from "../../assets/microsoft.png";

import { Link } from "react-router-dom";
const Container = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor:"#F6F8FF",
  padding:"30px 10px 50px 10px"
});

const AppContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  borderRadius:"15px"
}));

const DesktopContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#ddebfd",
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "15px",
}));

const AppImage = styled("img")({
  width: "100%",
  maxWidth: "184px",
  marginBottom: "20px",
});

const DesktopImage = styled("img")({
  width: "123%",
  maxWidth: "400px",
  marginBottom: "20px",
});

const Twosection = () => {
  return (
    <Container>
      <Grid
        container
        width={"82%"}
        justifyContent={"center"}
        gap={2}
        display={"flex"}
        flexDirection={{ lg: "row", sm: "column" }}
      >
        <Stack width={{ lg: "49%", sm: "100%" }}>
          <AppContainer>
            <Stack alignItems={"center"} padding={"40px"} gap={"13px"}>
              <Typography fontSize={"15px"} gutterBottom>
                MOBILE PLATFORM
              </Typography>
              <Typography
                fontSize={{ lg: "31px", sm: "24px", sm: "17px" }}
                gutterBottom
                fontWeight={"bold"}
                textAlign={"center"}
              >
                Smart Business Management Tool
              </Typography>
              <AppImage src={mobile} />
              <Stack direction={"row"} justifyContent={"center"}>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=io.saniiro.ims"
                  }
                >
                  <img src={play} alt="" width={"100%"} height={"100%"} />
                </Link>
                <Link to={""} style={{ width: "38%" }}>
                  <img src={software} alt="" width={"100%"} height={"100%"} />
                </Link>
              </Stack>
            </Stack>
          </AppContainer>
        </Stack>
        <Stack width={{ lg: "49%", sm: "100%" }}>
          <DesktopContainer>
            <Stack
              alignItems={"center"}
              padding={"40px"}
              gap={{ xl: "18px", lg: "15px" }}
            >
              <Box>
                <Typography variant="h6" gutterBottom fontSize={"15px"}>
                  WEB PLATFORM
                </Typography>
                <Typography
                  pt={"13px"}
                  fontSize={{ lg: "35px", smm: "24px", sm: "17px" }}
                  fontWeight={"bold"}
                  gutterBottom
                >
                  Billing and CRM Management
                </Typography>
              </Box>
              <DesktopImage src={computer} />
              <Stack direction={"row"} gap={2} justifyContent={"center"} height={"27px"}>
                {/* <Link
                  to={"https://app.saniiro.com/login"}
                  style={{ width: "48%" }}
                >
                  <img src={microsoft} alt="" width={"100%"} height={"100%"} />
                </Link> */}
              </Stack>
            </Stack>
          </DesktopContainer>
        </Stack>
      </Grid>
    </Container>
  );
};

export default Twosection;
