import React, { useEffect, useState } from "react";
// import log from "../assets/log.png"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import lo from "../../assets/lo.svg";
import { Stack } from "@mui/material";
import "./nav.css";
import Button from "../../utils/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
const NavLight = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const socialMediaIcons = [
    {
      id: "gmail",
      icon: <EmailIcon />,
      to: "mailto:sales@saniiro.com",
    },
    {
      id: "mobile",
      icon: <LocalPhoneIcon />,
      to: "tel:02269711021",
    },
  ];
  useEffect(() => {
    const handleScroll = () => {
      // Set state based on scroll position
      if (window.scrollY > 50) {
        // You can adjust the value '50' based on when you want the shadow to appear
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        position: "fixed",
        width: "100%",
        top: 0,
        left: 0,
        zIndex: 11, // Ensure the navbar is above other content
        backgroundColor: "white",
        transition: "box-shadow 0.3s",
        boxShadow: isScrolled ? "0 2px 4px rgba(0,0,0,0.3)" : "none",
      }}
    >
      <Stack
        width={"95%"}
        p={"10px 0"}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack
          sx={{
            width: { xl: "17%", lg: "20%", md: "24%", smm: "30%", sm: "40%" },
          }}
        >
          <Link to={"/"}>
            <img src={lo} alt="" style={{ width: "100%", height: "100%" }} />
          </Link>
        </Stack>

        <Stack direction={"row"}>
          <Stack
            direction={"row"}
            gap={{ xl: 3, sm: "10px" }}
            mr={{ xl: 4, sm: 2 }}
            alignItems={"center"}
          >
            {socialMediaIcons.map((item, index) => (
              <a
                key={index}
                href={item.to}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  textAlign: "center",
                }}
              >
                <Stack
                  width={"40px"}
                  height={"40px"}
                  borderRadius={"51%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={"#052973"}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#052973",
                      color: "white",
                      transform: "translateY(-8px)",
                      transition: "0.4s ease-out",
                    },
                  }}
                >
                  <Stack
                    width={"22px"}
                    height={"22px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {item.icon}
                  </Stack>
                </Stack>
              </a>
            ))}
          </Stack>
          <Link
            to={"https://app.saniiro.com"}
            target="_blank"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <Button
              sx={{
                display: { smm: "block", sm: "none" },
                p: "5px 13px 5px 13px ",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#F15B25",
                  color: "white",
                },
              }}
            >
              Login
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NavLight;
