// import React from "react";
// import $ from "jquery"; // Only if you keep jQuery

// class PayLink extends React.Component {
//   componentDidMount() {
//     // Assuming you've decided to keep using jQuery, ensure the script is loaded
//     const script = document.createElement("script");
//     script.src = "https://jssdk-uat.payu.in/bolt/bolt.min.js";
//     script.async = true;
//     document.body.appendChild(script);
//   }

//   handlePayment = () => {
//     const data = {
//       key: "JPM7Fg",
//       hash: "47382380b166fd8c6c1a3bb8230802a8e0e7da3b2e0c3e417ca1e5a14bc14a8d50f6a3e43fea096785b81a659f9b8248799946238def79aa796f43b93b2d592e",
//       txnid: "6543455",
//       amount: "89",
//       firstname: "Sanjay",
//       email: "sanjay.jangid@saniiro.com",
//       phone: "8796303322",
//       productinfo: "payu test",
//       surl: "https://test-payment-middleware.payu.in/simulatorResponse",
//       furl: "https://test-payment-middleware.payu.in/simulatorResponse",
//       enforce_paymethod: "creditcard|debitcard|HDFB|AXIB",
//       display_lang: "English",
//       drop_category: "creditcard|debitcard",
//       pg: "CC",
//       custom_note:
//         "You will be charged an extra amount of Rs 100 on this transaction",
//     };

//     const handlers = {
//       responseHandler: function (BOLT) {
//         if (BOLT.response.txnStatus === "SUCCESS") {
//           console.log("Your payment has been successful");
//         } else {
//           console.log("Payment failed. Please try again.");
//         }
//       },
//       catchException: function (BOLT) {
//         console.log("Payment failed. Please try again.");
//       },
//     };

//     window.bolt.launch(data, handlers);
//   };

//   render() {
//     return (
//       <button
//         id="submit"
//         style={{
//           display: "block",
//           border: "1px solid yellowgreen",
//           padding: "12px 8px",
//         }}
//         onClick={this.handlePayment}
//       >
//         Pay Now
//       </button>
//     );
//   }
// }

// export default PayLink;

// import React from "react";

// class PayLink extends React.Component {
//   componentDidMount() {
//     const script = document.createElement("script");
//     script.src = "https://jssdk.payu.in/bolt/bolt.min.js";
//     script.async = true;
//     document.body.appendChild(script);
//   }

//   initiatePayment = (paymentData) => {
//     const handlers = {
//       responseHandler: function (BOLT) {
//         if (BOLT.response.txnStatus === "SUCCESS") {
//           console.log("Your payment has been successful", BOLT.response);
//         } else {
//           console.log("Payment failed. Please try again.", BOLT.response);
//         }
//       },
//       catchException: function (BOLT) {
//         console.log("Payment failed. Please try again.",BOLT);
//       },
//     };

//     window.bolt.launch(paymentData, handlers);
//   };

//   render() {
//     return <div />; // Render nothing, functionality is in methods
//   }
// }

// export default PayLink;





// import React from "react";

// class PayLink extends React.Component {
//   componentDidMount() {
//     this.loadScript("https://jssdk.payu.in/bolt/bolt.min.js", () => {
//       console.log("PayU SDK loaded.");
//     });
//     this.loadScript("https://checkout.razorpay.com/v1/checkout.js", () => {
//       console.log("Razorpay SDK loaded.");
//     });
//   }

//   loadScript(src, callback) {
//     const script = document.createElement("script");
//     script.src = src;
//     script.onload = callback;
//     script.onerror = function () {
//       console.error(`Failed to load script ${src}`);
//     };
//     document.body.appendChild(script);
//   }

//   initiatePayment = (paymentData, gateway) => {
//     if (gateway === "PayU") {
//       window.bolt.launch(paymentData, {
//         responseHandler: function (BOLT) {
//           console.log("Your payment has been successful", BOLT.response);
//           // Here you can handle the successful payment post-processing
//         },
//         catchException: function (BOLT) {
//           console.error("Payment failed, error:", BOLT);
//           // Handle exceptions here
//         },
//       });
//     } else if (gateway === "RazorPay") {
//       const rzp = new window.Razorpay({
//         key: paymentData.key,
//         amount: paymentData.amount, // Amount is in the smallest currency unit
//         currency: paymentData.currency,
//         name: paymentData.name,
//         description: paymentData.description,
//         image: paymentData.image,
//         order_id: paymentData.order_id,
//         callback_url: paymentData.callback_url,
//         prefill: {
//           name: paymentData.prefill.name,
//           email: paymentData.prefill.email,
//           contact: paymentData.prefill.contact,
//         },
//         notes: paymentData.notes,
//         theme: paymentData.theme,
//         modal: {
//           ondismiss: function () {
//             console.log("Checkout form closed");
//           },
//         },
//       });

//       rzp.on("payment.failed", function (response) {
//         console.error(response.error.code);
//         console.error(response.error.description);
//         console.error(response.error.source);
//         console.error(response.error.step);
//         console.error(response.error.reason);
//         console.error(response.error.metadata.order_id);
//         console.error(response.error.metadata.payment_id);
//         // Handle the failed payment here.
//       });

//       rzp.open();
//     }
//   };

//   render() {
//     return <div />;
//   }
// }

// export default PayLink;



import React from "react";

class PayLink extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }

  initiatePayment = (paymentData) => {
    const options = {
      key: "rzp_test_eAQjRaiVNhy8ms", // Replace with your actual key from Razorpay dashboard
      amount: paymentData.amount, // amount in the smallest currency unit
      currency: "INR",
      name: paymentData.name,
      description: paymentData.description,
      image: "https://img.icons8.com/color/48/backend-development--v1.png",
      order_id: paymentData.order_id,
      handler: function (response) {
        // handle success
        alert(`Payment successful: ${response.razorpay_payment_id}`);
      },
      prefill: {
        name: paymentData.prefill.name,
        email: paymentData.prefill.email,
        contact: paymentData.prefill.contact,
      },
      notes: {
        address: paymentData.notes.address,
        merchant_order_id: paymentData.notes.merchant_order_id,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
    rzp.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
  };

  render() {
    return <div />; // Render nothing, functionality is in methods
  }
}

export default PayLink;
