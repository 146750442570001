import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ofc from "../../assets/ofc.svg";
import ofc2 from "../../assets/ofc2.svg";
import { Link } from "react-router-dom";
import lo from "../../assets/lo.png";
import play from "../../assets/play.svg";
import software from "../../assets/appstore.svg";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
// import ArrowDropDownIcon from "@mui/icons-material/KeyboardArrowRight";
import "./Footer.css";

const Footer = () => {
  const list1 = [
    {
      item: "About us",
      to: "/About-us",
    },
    {
      item: "Career",
      to: "/Resources/Career",
    },
    {
      item: "Updates",
      to: "/Updates",
    },

    {
      item: "Contact us",
      to: "/Contact-us",
    },
    {
      item: "Request Callback",
      to: "/requestCallback",
    },
    // {
    //   item: "Product Reviews",
    // },
    // {
    //   item: "Awards & Recognition",
    // },
    // {
    //   item: "Get Offer",
    // },
  ];
  const list2 = [
    {
      item: "Resources",
      to: "/HelpAndTraining",
    },

    {
      item: "Help & Training ",
      to: "/HelpAndTraining",
    },

    {
      item: "FAQ’s",
      to: "/Resources/faq",
    },
    {
      item: "Blog",
      to: "/Resources/Blog",
    },
    {
      item: "Videos",
      to: "/Resources/Videos",
    },
    // {
    //   item: "Gallery",
    // },
    // {
    //   item: "Security & Performance",
    // },
    // {
    //   item: "Contact support",
    // },
  ];
  const socialMediaIcons = [
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/saniiro-technologies-pvt-ltd/mycompany/?viewAsMember=true",
    },
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/saniiro.software",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UChRag0dYEDDoTYzyMmAqaqg",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/business_accounting_software/",
    },
  ];
  const list3 = [
    {
      item: "Case studies",
      to: "/Resources/CaseStudy",
    },
    {
      item: "Testimonials",
      to: "/Resources/Testimonials",
    },
    {
      item: "Become A Partner",
      to: "/Resources/BecomeAPartner",
    },
  ];

  const list4 = [
    {
      item: "Legal",
      to: "/subfooter/legal",
    },
    {
      item: "Terms & Conditions",
      to: "/subfooter/terms-and-conditions",
    },
    {
      item: "Privacy Policy",
      to: "/subfooter/privacy-policy",
    },
    {
      item: "Canclenatin/Refund Policy",
      to: "/subfooter/cancalation-and-refund-policy",
    },
    {
      item: "Employee Login",
      to: "/subfooter/employee-login",
    },
  ];

  return (
    <>
      <Stack
        border={"1px solid darkgrey"}
        margin={"10px"}
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
      >
        <Stack
          bgcolor={"#FAF9F6"}
          p={"40px 0"}
          direction={"row"}
          justifyContent={"space-evenly"}
        >
          <Stack width={{ xl: "21%", lg: "26%" }}>
            <Stack gap={2}>
              <Stack
                sx={{ width: { xl: "60%", lg: "60%", md: "30%", sm: "34%" } }}
              >
                <img
                  src={lo}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Stack>
              <Typography
                color={"black"}
                fontWeight={"bold"}
                fontSize={"15px"}
                lineHeight={0.7}
                letterSpacing={"2px"}
              >
                Made with ❤️ from Pink City
              </Typography>
              <Stack
                direction={"row"}
                gap={3}
                mr={{
                  xs: 0,
                  lg: 7,
                }}
                alignItems={"center"}
              >
                {socialMediaIcons.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      textAlign: "center",
                    }}
                  >
                    <Stack
                      width={"40px"}
                      height={"40px"}
                      borderRadius={"51%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      color={"#052973"}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#052973",
                          color: "white",
                          transform: "translateY(-8px)",
                          transition: "0.4s ease-out",
                        },
                      }}
                    >
                      <Stack
                        width={"22px"}
                        height={"22px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {item.icon}
                      </Stack>
                    </Stack>
                  </a>
                ))}
              </Stack>

              <Stack gap={1}>
                <Stack
                  direction={"row"}
                  border={"1px solid white"}
                  bgcolor={"#F15B25"}
                  borderRadius={1}
                  width={{ xl: "165px" }}
                  gap={1}
                  p={"9px 12px"}
                >
                  <EmailIcon sx={{ color: "white" }} />
                  <Typography color={"white"}>sales@saniiro.com</Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  border={"1px solid white"}
                  bgcolor={"#F15B25"}
                  borderRadius={1}
                  width={{ xl: "165px" }}
                  gap={1}
                  p={"9px 12px"}
                >
                  <CallIcon sx={{ color: "white" }} />
                  <Typography color={"white"}>02269711021</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={4} alignItems={"Center"}>
            <Typography
              pt={2}
              color={"black"}
              fontWeight={"bold"}
              fontSize={"25px"}
              lineHeight={0.7}
            >
              DOWNLOAD APP
            </Typography>
            <Stack gap={1.5} width={"80%"}>
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=io.saniiro.ims"
                }
              >
                <img src={play} alt="" width={"100%"} height={"100%"} />
              </Link>
              <Link to={""}>
                <img src={software} alt="" width={"100%"} height={"100%"} />
              </Link>
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography color={"black"} fontWeight={"bold"} fontSize={"25px"}>
              Saniiro Contact
            </Typography>
            <Stack gap={1}>
              <Stack
                p={2}
                border={"1px solid white"}
                bgcolor={"#F15B25"}
                width={"265px"}
                borderRadius={1}
                direction={"row"}
                gap={3}
              >
                <Stack justifyContent={"center"}>
                  <img
                    src={ofc}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    fontWeight={"bold"}
                    color={"white"}
                    fontSize={"22px"}
                  >
                    Head Office
                  </Typography>
                  <Typography color={"white"}>
                    613-5th Floor, Apex Mall, Lalkothi, Jaipur - 302015
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                p={2}
                border={"1px solid white"}
                bgcolor={"#F15B25"}
                width={"265px"}
                borderRadius={1}
                direction={"row"}
                gap={3}
              >
                <Stack justifyContent={"center"}>
                  <img
                    src={ofc2}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    fontWeight={"bold"}
                    color={"white"}
                    fontSize={"22px"}
                  >
                    Sale(s) Office
                  </Typography>
                  <Typography color={"white"}>
                    Jaipur, Mumbai, Lucknow, Pune, Ahmedabad
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        border={"1px solid darkgrey"}
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
        }}
      >
        <Stack
          bgcolor={"#FAF9F6"}
          sx={{
            margin: {
              xs: "0px 8px",
            },
          }}
        >
          <Stack gap={2} p={"20px 0"}>
            <Stack>
              <Stack gap={2} alignItems={"Center"}>
                <Stack
                  sx={{
                    width: { xl: "60%", md: "24%", smm: "27%", sm: "35%" },
                  }}
                >
                  <img
                    src={lo}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </Stack>
                <Typography
                  color={"black"}
                  fontWeight={"bold"}
                  fontSize={"15px"}
                  lineHeight={0.7}
                >
                  Made with ❤️ from Pink City
                </Typography>
                <Stack
                  direction={"row"}
                  gap={3}
                  mr={{
                    xs: 0,
                    lg: 7,
                  }}
                  alignItems={"center"}
                >
                  {socialMediaIcons.map((item, index) => (
                    <a
                      key={index}
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        textAlign: "center",
                      }}
                    >
                      <Stack
                        width={"40px"}
                        height={"40px"}
                        borderRadius={"51%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        color={"#052973"}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#052973",
                            color: "white",
                            transform: "translateY(-8px)",
                            transition: "0.4s ease-out",
                          },
                        }}
                      >
                        <Stack
                          width={"22px"}
                          height={"22px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          {item.icon}
                        </Stack>
                      </Stack>
                    </a>
                  ))}
                </Stack>

                <Stack gap={1}>
                  <Stack
                    direction={"row"}
                    border={"1px solid white"}
                    bgcolor={"#F15B25"}
                    borderRadius={1}
                    width={{ md: "none", sm: "none" }}
                    gap={1}
                    p={"9px 12px"}
                  >
                    <EmailIcon sx={{ color: "white" }} />
                    <Typography color={"white"}>sales@saniiro.com</Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    border={"1px solid white"}
                    bgcolor={"#F15B25"}
                    borderRadius={1}
                    width={{ md: "none", sm: "none" }}
                    gap={1}
                    p={"9px 12px"}
                    justifyContent={"center"}
                  >
                    <CallIcon sx={{ color: "white" }} />
                    <Typography color={"white"}>02269711021</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              gap={2}
              sx={{
                margin: "0px auto",
              }}
            >
              <Typography
                color={"#052973"}
                fontWeight={"bold"}
                fontSize={"25px"}
                sx={{
                  margin: "20px auto 0",
                }}
              >
                Saniiro Contact
              </Typography>
              <Stack
                sx={{
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  gap: {
                    xs: 2,
                    md: 10,
                  },
                }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Stack
                  p={2}
                  border={"1px solid white"}
                  bgcolor={"#F15B25"}
                  sx={{
                    width: {
                      xs: "80%",
                      md: "265px",
                    },
                  }}
                  borderRadius={1}
                  direction={"row"}
                  gap={3}
                >
                  <Stack justifyContent={"center"}>
                    <img
                      src={ofc}
                      alt=""
                      style={{ width: "50px", height: "50px" }}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      fontWeight={"bold"}
                      color={"white"}
                      fontSize={"22px"}
                    >
                      Head Office
                    </Typography>
                    <Typography color={"white"}>
                      613-5th Floor, Apex Mall, Lalkothi, Jaipur - 302015
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  p={2}
                  border={"1px solid white"}
                  bgcolor={"#F15B25"}
                  sx={{
                    width: {
                      xs: "80%",
                      md: "265px",
                    },
                  }}
                  borderRadius={1}
                  direction={"row"}
                  gap={3}
                >
                  <Stack justifyContent={"center"}>
                    <img
                      src={ofc2}
                      alt=""
                      style={{ width: "50px", height: "50px" }}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      fontWeight={"bold"}
                      color={"white"}
                      fontSize={"22px"}
                    >
                      Sale(s) Office
                    </Typography>
                    <Typography color={"white"}>
                      Jaipur, Mumbai, Lucknow, Pune, Ahmedabad
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <hr
              style={{
                border: "0.5px solid white",
              }}
            />

            <Stack gap={2} alignItems={"center"} marginTop={"10px"}>
              <Typography
                color={"#052973"}
                fontWeight={"bold"}
                fontSize={"25px"}
                lineHeight={0.7}
              >
                DOWNLOAD APP
              </Typography>
              <Stack gap={1.5} width={"40%"}>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=io.saniiro.ims"
                  }
                >
                  <img src={play} alt="" width={"100%"} height={"100%"} />
                </Link>
                <Link to={""}>
                  <img src={software} alt="" width={"100%"} height={"100%"} />
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Footer;
